<template>
	<div class="order-details return-details">
		<!-- 退换/售后状态 -->
		<div class="state-wrapper">
			<div class="my-title clearfix">
				<span class="">售后详情</span>
			</div>
			<returnStatus :detail="detail"></returnStatus>
		</div>
		<!-- 退货地址 -->
		<div class="return-add-wrapper" v-if="detail.type == RefundTypeEnum.RETURN.value
		&& detail.audit_status == AuditStatusEnum.REVIEWED.value">
			<div class="my-title clearfix">
				<span class="">退货地址</span>
			</div>
			<div class="return-add clearfix">
				<ul class=" clearfix">
					<li>收货人：{{ detail.address.name }}</li>
					<li>联系电话：{{ detail.address.phone }}</li>
					<li>详细地址：
						<span v-for="(region, idx) in detail.address.region" :key="idx">{{region}}</span>
						{{ detail.address.detail }}</li>
				</ul>
				<ul class=" clearfix">
					<li class="tips">· 未与卖家协商一致情况下，请勿寄到付或平邮</li>
					<li class="tips">· 请填写真实有效物流信息</li>
				</ul>
			</div>
		</div>
		<!-- 填写退货快递 -->
		<div class="order-info-wrapper" v-if="detail.type == RefundTypeEnum.RETURN.value && detail.audit_status == AuditStatusEnum.REVIEWED.value && !detail.is_user_send">
			<div class="my-title clearfix">
				<span class="">退货快递信息</span>
			</div>
			<el-form :model="formData" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="物流公司" prop="company">
					<el-select v-model="formData.expressId" placeholder="请选择快递公司">
						<el-option :label="express.express_name" :value="express.express_id" v-for="(express, index) in expressList" :key="index"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="物流单号" prop="number">
					<el-input v-model="formData.expressNo"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit">提交</el-button>
				</el-form-item>
			</el-form>
		</div>
		<!-- 订单信息 -->
		<div class="order-info-wrapper">
			<div class="my-title clearfix">
				<span class="">售后商品</span>
			</div>
			<returnInfo :detail="detail"></returnInfo>
		</div>
	</div>
</template>

<script>
	import { AuditStatusEnum, RefundTypeEnum } from '@/common/enum/order/refund'
	import * as RefundApi from '@/api/refund'
	import * as ExpressApi from '@/api/express'
	import returnStatus from "./return-status.vue"
	import returnInfo from "./return-info.vue"
	export default {
		components: {
			returnStatus,
			returnInfo
		},
		data() {
			return {
				// 枚举类
				AuditStatusEnum,
				RefundTypeEnum,
				// 售后单ID
				orderRefundId: null,
				// 售后单详情
				detail: {},
				// 物流公司列表
				expressList: [],
				// 表单数据
				formData: {
				  // 物流公司ID
				  expressId: null,
				  // 物流单号
				  expressNo: ''
				},
				// 选择的物流公司索引
				expressIndex: -1,

				rules: {

				}
			};
		},
		created() {
			if(this.$route.query.orderRefundId){
				this.orderRefundId = this.$route.query.orderRefundId
			}
			// 获取页面数据
			this.getPageData()
		},
		methods: {
			// 获取页面数据
			getPageData() {
			  const app = this
			  Promise.all([app.getRefundDetail(), app.getExpressList()])
			    .then(result => {

			    })
			},

			// 获取售后单详情
			getRefundDetail() {
			  const app = this
			  return new Promise((resolve, reject) => {
			    RefundApi.detail(app.orderRefundId)
			      .then(result => {
			        app.detail = result.data.detail
			        resolve()
			      })
			      .catch(err => reject(err))
			  })
			},

			// 获取物流公司列表
			getExpressList() {
			  const app = this
			  return new Promise((resolve, reject) => {
			    ExpressApi.list()
			      .then(result => {
			        app.expressList = result.data.list
			        resolve()
			      })
			      .catch(err => reject(err))
			  })
			},
			// 选择物流公司
			onChangeExpress(e) {
			  const expressIndex = e.detail.value
			  const { expressList } = this
			  this.expressIndex = expressIndex
			  this.formData.expressId = expressList[expressIndex].express_id
			},

			// 表单提交
			onSubmit() {
			  const app = this
			  // 提交到后端
			  RefundApi.delivery(app.orderRefundId, app.formData)
			    .then(result => {
				  app.$message.success(result.message)
			      setTimeout(() => {
			        app.getPageData()
			      }, 1500)
			    })
			}
		}
	}
</script>

<style lang="scss">
	@import "../../assets/css/order-details.scss";
	@import "../../assets/css/return-details.scss";
</style>
