<template>
	<div class="state clearfix">
		<div class="left">
			<p class="type">售后类型：{{ RefundTypeEnum[detail.type].name }}</p>
			<p class="state-txt green">{{ detail.state_text }}</p>
			<p v-if="detail.state_text == '等待确认中'"><el-button size="mini" plain @click="comfirmRefund(detail)">确定</el-button></p>
		</div>
		<div class="right">
			<!-- <el-steps :active="1" finish-status="success" align-center>
				<el-step title="提交申请" description="2021-06-18 21:41:18"></el-step>
				<el-step title="客服审核" description="2021-06-18 21:41:18"></el-step>
				<el-step title="商家收货" description="2021-06-18 21:41:18"></el-step>
				<el-step title="完成" description="2021-06-18 21:41:18"></el-step>
			</el-steps> -->
		</div>
	</div>
</template>

<script>
	import * as RefundApi from '@/api/refund'
	import {
		RefundTypeEnum
	} from '@/common/enum/order/refund'
	export default {
		props: {
			detail: Object
		},
		data() {
			return {
				RefundTypeEnum
			}
		},
		methods: {
			comfirmRefund(row) {
				//confirm
				this.$confirm('确定要确认退款吗？', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						this.handleConfirmRefund(row)
					})
			},
			handleConfirmRefund(row) {
				RefundApi.confirmRefund(row.order_refund_id)
					.then(() => {
						this.getList()
						this.$message({
							message: '操作成功',
							type: 'success'
						});
					})
			}
		}
	}
</script>

<style>
</style>