<template>
	<div class="order-info return-info">
		<div class="order-info-con">
			<ul>
                <li><span>售后单号：</span>{{ detail.order_refund_no }}</li>
                <li><span>原订单号：</span>
                    <router-link :to="'orderdetails?id='+detail.orderData.order_id">
                        {{ detail.orderData.order_no }}
                    </router-link>
                </li>
				<li><span>售后类型：</span>{{ RefundTypeEnum[detail.type].name }}</li>
				<li><span>申请时间：</span>{{ detail.create_time }}</li>
				<!-- <li><span>退款方式：</span>原返</li>
				<li><span>预计退款至：</span>余额</li> -->
				<li><span>申请原因：</span>{{ detail.apply_desc }}</li>
				<li v-if="detail.status.value == 10">
					<span>拒绝原因：</span>{{ detail.refuse_desc }}</li>
			</ul>
		</div>
		<el-table :data="detail.refundOrderGoods" stripe>
			<el-table-column prop="create_time" label="商品">
				<template slot-scope="scope">
					<router-link :to="{ path: '/details', query: { id: scope.row.orderGoods.goods_id }}">
						<div class="text">
							<div class="title">{{ scope.row.orderGoods.goods_name }}</div>
							<div class="storehouse" v-for="(props, idx) in scope.row.orderGoods.goods_props" :key="idx">
								{{ props.value.name }}</div>
						</div>
					</router-link>
				</template>
			</el-table-column>
			<el-table-column prop="orderGoods.grade_goods_price" label="单价（元）" width="150" align="center">
				<template slot-scope="scope">
					¥{{ scope.row.orderGoods.grade_goods_price > 0 ?
                        scope.row.orderGoods.grade_goods_price : scope.row.orderGoods.goods_price }}
				</template>
			</el-table-column>
			<el-table-column prop="refund_num" label="数量" width="150" align="center"></el-table-column>
			<el-table-column prop="warehouse_name" label="退货仓库" width="200" align="center"></el-table-column>
			<el-table-column prop="" label="付款金额（元）" width="150" align="center">
				<template slot-scope="scope">
					<span class="f16 red">¥{{ scope.row.orderGoods.grade_goods_price * scope.row.refund_num }}</span>
				</template>
			</el-table-column>
		</el-table>
		<!-- 结算 -->
		<div class="settlement-bottom clearfix">
			<!-- <p>-</p> -->
			<!-- <p>-</p> -->
			<p v-if="detail.status == 20 && (detail.type == 10 || detail.type == 30)" class="total">
				<span>¥{{ detail.refund_money }}</span><em>已退款金额：</em></p>
		</div>
	</div>
</template>

<script>
	import { RefundTypeEnum } from '@/common/enum/order/refund'
	export default {
		components: {},
		props:{
			detail: Object
		},
		data() {
			return {
				RefundTypeEnum
			};
		},
		methods:{
		}
	}
</script>

<style lang="scss">
	// @import "../../assets/css/settlement.scss"
</style>
